@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    background-color: #2ba6cb;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Login background {
    background-color: #2ba6cb;
    padding: 0 90px;
  }

  #divBackground {
    min-height:50px;
    background:#148CE2;
  }

}

