.upload-div {
    cursor: pointer;
    margin-top: 9px;
    font-size: 16px;
    height: 42px;
    width: 150px;
  }
.upload-input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
  }

  .calendar-open{
    color: blue;
  }

  .view-text-head{
    font-weight: bold;
  }
  .view-text{
    font-weight: 500;
    margin-left: 10px;
  }

  .btn-green{
    background-color: #19975d;
    border-color: #367fa9;
  }